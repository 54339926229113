'use client'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { ThemeProvider } from 'styled-components'
import { theme } from '@/styles'
import { TranslationsProvider, getTranslations, ModalsProvider, SessionProvider, DatesTypeProvider } from '@/context'
import { AnimatedBackground } from './AnimatedBackground'

const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })
const DynamicModalNewTask = dynamic(() => import('@/components/modals/ModalNewTask').then(mod => mod.ModalNewTask))
const DynamicModalNewProject = dynamic(() => import('@/components/modals/ModalNewProject').then(mod => mod.ModalNewProject))

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  const dictionary = getTranslations('es')

  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <TranslationsProvider localization={dictionary}>
          <DatesTypeProvider>
            <SessionProvider>
              <ModalsProvider>
                {children}
                <AnimatedBackground />
                <DynamicModalNewTask />
                <DynamicModalNewProject />
                <DynamicModalMenu />
              </ModalsProvider>
            </SessionProvider>
          </DatesTypeProvider>
        </TranslationsProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
