import { motion } from 'framer-motion'
import { FC, memo } from 'react'
import styled from 'styled-components'

/**
 * Generates a random rotation speed within a specified range.
 * @param min The minimum value of the speed range.
 * @param max The maximum value of the speed range.
 * @returns A random speed between the specified minimum and maximum.
 */
const getRandomSpeed = (min: number, max: number) => Math.random() * (max - min) + min // Generates a random speed within the range [min, max]

const animateCircle = (radius: number) => ({
  opacity: .2,
  rotate: [0, 360, 0],
  x: [
    radius * Math.cos(0 * Math.PI / 180),                 // 0 degrees
    radius * Math.cos(45 * Math.PI / 180),                // 45 degrees
    radius * Math.cos(90 * Math.PI / 180),                // 90 degrees
    radius * Math.cos(135 * Math.PI / 180),               // 135 degrees
    radius * Math.cos(180 * Math.PI / 180),               // 180 degrees
    radius * Math.cos(225 * Math.PI / 180),               // 225 degrees
    radius * Math.cos(270 * Math.PI / 180),               // 270 degrees
    radius * Math.cos(315 * Math.PI / 180),               // 315 degrees
    radius * Math.cos(360 * Math.PI / 180)                // 360 degrees, same as 0
  ],
  y: [
    radius * Math.sin(0 * Math.PI / 180),                 // 0 degrees
    radius * Math.sin(45 * Math.PI / 180),                // 45 degrees
    radius * Math.sin(90 * Math.PI / 180),                // 90 degrees
    radius * Math.sin(135 * Math.PI / 180),               // 135 degrees
    radius * Math.sin(180 * Math.PI / 180),               // 180 degrees
    radius * Math.sin(225 * Math.PI / 180),               // 225 degrees
    radius * Math.sin(270 * Math.PI / 180),               // 270 degrees
    radius * Math.sin(315 * Math.PI / 180),               // 315 degrees
    radius * Math.sin(360 * Math.PI / 180)                // 360 degrees, same as 0
  ],
})

const transition = (duration: number) => ({
  opacity: {
    duration: 1
  },
  rotate: {
    loop: Infinity,
    ease: 'linear',
    duration: duration,
  },
  default: {
    repeat: Infinity,
    repeatType: 'loop' as const,
    ease: 'linear' as const,
    duration: duration,
    times: [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]  // Timing for keyframes
  }
})

export const AnimatedBackground:FC = memo(() => {
  return (
    <Div>
      <ImgMagenta src='/images/svg/bg--magenta.svg' alt='Background magenta shape' draggable={false} initial={{ opacity: 0 }} animate={animateCircle(300)} transition={transition(getRandomSpeed(150, 300))} />
      <ImgPurple src='/images/svg/bg--purple.svg' alt='Background purple shape' draggable={false} initial={{ opacity: 0 }} animate={animateCircle(250)} transition={transition(getRandomSpeed(200, 300))} />
      <ImgOrange src='/images/svg/bg--orange.svg' alt='Background orange shape' draggable={false} initial={{ opacity: 0 }} animate={animateCircle(200)} transition={transition(getRandomSpeed(100, 300))} />
    </Div>
  )
})

const Div = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  width: 100vw;

  img {
    filter: blur(5vw);
    opacity: 0;
    pointer-events: none;
    position: fixed;
    user-select: none;
    transform-origin: center center;
  }
`

const ImgMagenta = styled(motion.img)`
  bottom: -55%;
  right: 55%;
`

const ImgPurple = styled(motion.img)`
  right: 30%;
  top: -20%;
`

const ImgOrange = styled(motion.img)`
  left: 30%;
  top: 10%;
`
